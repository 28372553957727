<template>
  <div class="pb-2">
    <h4 class="text-center font-medium-5">
      <!--      {{ $t('User List') }}-->
    </h4>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      trash-list-path="user-trash-list"
      :create-page-url="{ name: 'user-create' }"
      :can-create="ACCESS_ABILITY_FOR_SYSTEM_USERS"
      :is-searchable="false"
      :can-show-trash-list="ACCESS_ABILITY_FOR_SYSTEM_USERS"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getActiveUsersList`,
      }"
    >

      <template #cell(name)="{ data }">
        {{ data.item.firstname + ' ' + data.item.lastname }}
      </template>

      <template #cell(warehouse)="{ data }">
        {{ data.value.label }}
      </template>

      <template #cell(status)="{ data }">
        {{ title(data.value) }}
      </template>

      <template #cell(role)="{ data }">
        {{ data.value.name }}
      </template>

      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <Can
            :i="ACCESS_ABILITY_FOR_SYSTEM_USERS.action"
            :a="ACCESS_ABILITY_FOR_SYSTEM_USERS.subject"
          >
            <feather-icon
              v-b-tooltip.noninteractive.hover.bottom
              icon="LStockEyeIcon"
              size="32"
              class="mr-1 cursor-pointer"
              :title="$t('View')"
              @click="gotoNewPage({ name: 'user-update', params: { id: data.item.id } }, $event)"
            />
            <feather-icon
              v-b-tooltip.noninteractive.hover.bottom
              icon="LHandsIconUpdated"
              size="32"
              class="mr-1 cursor-pointer"
              :title="$t('Audit')"
              @click="gotoNewPage({ name: 'user-audit', params: { id: data.item.id } }, $event)"
            />
            <!--            <feather-icon-->
            <!--              v-b-tooltip.noninteractive.hover.bottom-->
            <!--              icon="LTrashIconKits"-->
            <!--              size="32"-->
            <!--              class="cursor-pointer"-->
            <!--              :title="$t('Set Inactive')"-->
            <!--              @click="setInActive(data.item)"-->
            <!--            />-->
          </Can>
        </div>
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import userMenuItems from '@/views/components/sub-menu/settings-sub-menu/users'
// eslint-disable-next-line import/no-cycle
import { title } from '@core/utils/filter'
import { VBTooltip } from 'bootstrap-vue'
import LDraggableTable from '@/views/components/LDraggableTable.vue'
import tableConfig from '../userConfig'

export default {
  name: 'UserList',
  components: {
    LDraggableTable,
    LTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  created() {
    this.$emit('updateMenu', 'user-list')
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    // setInActive(user) {
    //   this.confirmNotification(this, user, `${this.MODULE_NAME}/setInActive`,
    //     { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
    //     .then(() => {
    //       this.refetchData()
    //     })
    // },
    redirect() {
      this.$router.push({ name: 'user-audit' })
    },
    audit(data) {
      this.$router.push({ name: 'user-audit', params: { id: data.id } })
    },
  },
  setup() {
    const MODULE_NAME = 'users'
    const { menuItems } = userMenuItems('users')
    const { tableColumns, ACCESS_ABILITY_FOR_SYSTEM_USERS } = tableConfig()
    return {
      tableColumns,
      MODULE_NAME,
      menuItems,
      title,
      ACCESS_ABILITY_FOR_SYSTEM_USERS,
    }
  },
}
</script>
