var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('h4',{staticClass:"text-center font-medium-5"}),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"trash-list-path":"user-trash-list","create-page-url":{ name: 'user-create' },"can-create":_vm.ACCESS_ABILITY_FOR_SYSTEM_USERS,"is-searchable":false,"can-show-trash-list":_vm.ACCESS_ABILITY_FOR_SYSTEM_USERS,"table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/getActiveUsersList"),
    }},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.firstname + ' ' + data.item.lastname)+" ")]}},{key:"cell(warehouse)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.value.label)+" ")]}},{key:"cell(status)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.title(data.value))+" ")]}},{key:"cell(role)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.value.name)+" ")]}},{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-nowrap"},[_c('Can',{attrs:{"i":_vm.ACCESS_ABILITY_FOR_SYSTEM_USERS.action,"a":_vm.ACCESS_ABILITY_FOR_SYSTEM_USERS.subject}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"mr-1 cursor-pointer",attrs:{"icon":"LStockEyeIcon","size":"32","title":_vm.$t('View')},on:{"click":function($event){return _vm.gotoNewPage({ name: 'user-update', params: { id: data.item.id } }, $event)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"mr-1 cursor-pointer",attrs:{"icon":"LHandsIconUpdated","size":"32","title":_vm.$t('Audit')},on:{"click":function($event){return _vm.gotoNewPage({ name: 'user-audit', params: { id: data.item.id } }, $event)}}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }